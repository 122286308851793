import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import rose from '../../src/assets/logo.png';
import whatsapp512 from '../../src/assets/whatsapp-white.png';



export const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-light shadow-sm navbar-transparent">
            <div className="container">
                {/* <a className="navbar-brand" href="#">
                        <img src={whatsapp} alt="" width="100%" />
                    </a> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="#"><b>Transfers</b></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#"><b>Sightseeing</b></a>
                        </li>
                        <li className="nav-item me-md-4">
                            <a className="nav-link" href="#"><b>Staff Transport</b></a>
                        </li>
                        <li className="nav-item">
                            <a className="btn btn-success" href="#"><b>BOOK NOW</b></a>
                        </li>
                    </ul>
                    {/* <form className="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form> */}
                </div>
            </div>
        </nav>
    )
}


export const Pods = () => {
    return (
        <div className="col-md-7 col-11 mx-auto mb-4 services bg-white shadow-sm rounded p-4">
            <div className="row">
                <div className="col-md-4 col-12 p-3">
                    <div className="mb-3 img-thumbnail-bmw" />
                    <h5>Airport and port transfers</h5>
                    <p>Personalised transfers designed to make you feel welcome</p>
                    <Link to={'/transfers'} className="btn btn-success">BOOK NOW</Link>
                </div>
                <div className="col-md-4 col-12 p-3">
                    <div className="mb-3 img-thumbnail-tours" />
                    <h5>Sightseeing tours</h5>
                    <p>Tour the places you’ve dreamt about, guided by your personal driver</p>
                    <Link to={'/tours'} className="btn btn-success">BOOK NOW</Link>
                </div>
                <div className="col-md-4 col-12 p-3">
                    <div className="mb-3 img-thumbnail-staff" />
                    <h5>Staff transport</h5>
                    <p>Rides you can trust, when and where your staff need them</p>
                    <Link to={'/staff'} className="btn btn-success">BOOK NOW</Link>
                </div>
            </div>
        </div>
    )
}

export const Logo = () => {
    return (
        <div className="col-11 mx-auto mb-5 text-white text-center">
            <div className="logo mx-auto bg-white shadow-sm">
                <img src={rose} alt="" width="100%" />
            </div>
        </div>
    )
}
