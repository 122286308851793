import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';



import Home from './screens/Home/index';
import Transfers from './screens/Transfers/index';
import Tours from './screens/Tours/index';
import Staff from './screens/Staff/index';

const App = () => {
    return (
            <Router>
                <Routes>
                <Route path="/home" element={<Home/>}/>
                <Route path="/transfers" element={<Transfers/>}/>
                <Route path="/tours" element={<Tours/>}/>
                <Route path="/staff" element={<Staff/>}/>
                <Route path="*" element={<Home />} />
                </Routes>
            </Router>
    )
};

export default App;
