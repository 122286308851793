import React from 'react'
import {Pods, Logo} from '../../components/_NavPods'
import whatsapp from '../../assets/whatsapp.png';


const Home = (props) => {
    return (
        <main>
            <section className="intro align-items-center  position-relative  overflow-hidden py-5">
                <Logo />
                <div className="col-11 mx-auto text-white text-center py-3 py-md-5">
                    <h1>We are Rose Shuttles</h1>
                    <h3>and we're here to transport you.</h3>
                </div>
            </section>
            <section>
                <Pods/>
            </section>
            <div className="col-md-7 col-11 mx-auto mb-5 bg-white shadow-sm rounded p-4">
                <h5>All about us</h5>
                <p>Founded in 2020 in Cape Town, South Africa, Rose Shuttles goes above and beyond the commoditized transfer service as the first company to deliver a holistic, in-destination travel experience. From the moment a traveler arrives in Cape Town, until they return home, Rose Shuttles accommodates all their travel needs (transfers, travel products, things to do, information) as the easiest, friendliest and most personalized solution.</p>
            </div>
            <section className="py-5 bg-white">
                <div className="col-10 col-lg-3 p-3 mx-auto rounded-3 bg-light text-center">
                    <h3>Make a booking</h3>
                    <hr/>
                    <h5>Contact: +27 79 218 1004</h5><br/>
                    <h5>Email: shafick@roseshuttles.com</h5>
                </div>
            </section>
            <footer className="text-center text-white p-3 py-lg-3 px-lg-5">&copy; Rose Shuttles 2020</footer>
        </main>
    )
}


export default Home;

