import React from 'react'
import { Pods, Logo } from '../../components/_NavPods'

const Staff = (props) => {
    return (
        <main>
            <section className="intro-staff align-items-center  position-relative  overflow-hidden py-5">
                <Logo />
                <div className="col-11 mx-auto text-white text-center py-3 py-md-5">
                    <h1>Your Most Valuable Business Asset</h1>
                    <h3>we'll transport them safely.</h3>
                </div>
            </section>
            <section>
                <Pods />
            </section>
            <div className="col-md-7 col-11 mx-auto mb-5 bg-white shadow-sm rounded p-4">
                <h3>Staff transport</h3>
                <p>Improve employee wellbeing & performance
                    Safe, convenient and punctual transport will help to improve employee productivity and wellbeing.</p>
            </div>
            <section className="py-5 bg-white">
                <div className="col-10 col-lg-3 p-3 mx-auto rounded-3 bg-light text-center">
                    <h3>Make a booking</h3>
                    <hr/>
                    <h5>Contact: +27 79 218 1004</h5><br/>
                    <h5>Email: shafick@roseshuttles.com</h5>
                </div>
            </section>
            <footer className="text-center text-white p-3 py-lg-3 px-lg-5">&copy; Rose Shuttles 2020</footer>
        </main>
    )
}


export default Staff;

