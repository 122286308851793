import React from 'react'
import {Pods, Logo} from '../../components/_NavPods'


const Tours = (props) => {
    return (
        <main>
            <section className="intro-tours align-items-center  position-relative  overflow-hidden py-5">
                <Logo/>
                <div className="col-11 mx-auto text-white text-center py-3 py-md-5">
                    <h1>Adventure Awaits You</h1>
                    <h3>tours designed for you.</h3>
                </div>
            </section>
            <section>
                <Pods/>
            </section>
            <div className="col-md-7 col-11 mx-auto mb-5 bg-white shadow-sm rounded p-4">
                <h3>Sightseeing tours</h3>
                <p>Discover the wild scenery, unique culture, and rugged coastline of the Cape Peninsula during this
                    full-day sightseeing tour. Drive along the roads of this incredible peninsula, stopping to explore
                    pristine beaches, charming coastal towns, and local wildlife.
                    Observe a community of penguins on
                    Boulders Beach, visit two oceans and journey to the edge of the African continent at the Cape of
                    Good Hope. A guide, some entrance fees, and round-trip hotel transport are also included.</p>
                <ul>
                    <li>Explore the Cape Peninsula during a full-day sightseeing trip from Cape Town</li>
                    <li>Discover the Peninsula's incredible mix of mountains, oceans, pristine beaches, charming small towns, and local wildlife</li>
                    <li>Observe the penguin community of Boulders Beach</li>
                    <li>Visit the southwestern-most point on the African continent at the Cape of Good Hope</li>
                </ul>

                </div>
            <section className="py-5 bg-white">
                <div className="col-10 col-lg-3 p-3 mx-auto rounded-3 bg-light text-center">
                    <h3>Make a booking</h3>
                    <hr/>
                    <h5>Contact: +27 79 218 1004</h5><br/>
                    <h5>Email: shafick@roseshuttles.com</h5>
                </div>
            </section>
            <footer className="text-center text-white p-3 py-lg-3 px-lg-5">&copy; Rose Shuttles 2020</footer>
        </main>
    )
}


export default Tours;

