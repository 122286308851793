import React, {useState} from 'react'
import {Pods, Logo} from '../../components/_NavPods'
import ReactWhatsapp from 'react-whatsapp';


const Transfers = (props) => {
    const [message, setMessage] = useState('');
    return (
        <main>
            <section className="intro-transfers  align-items-center  position-relative  overflow-hidden py-5">
                 <Logo />
                <div className="col-11 mx-auto text-white text-center py-3 py-md-5">
                    <h1>To Your Destination With Comfort</h1>
                    <h3>safe and sound.</h3>
                </div>
            </section>
            <section>
                <Pods />
            </section>
            <div className="col-md-7 col-11 mx-auto mb-3 bg-white shadow-sm rounded p-4">
                <h3>Airport and port transfers</h3>
                <p>Safe, Professional and Reliable Shuttle Services in Cape Town Getting from the Airport to Hotel in a foreign country can often be a stressful experience, especially if you are not familiar with the local culture. Tourists are often overcharged, which is frequently made worse by not knowing the local language. As such, it's important to secure a trusted, reliable means to get you around. It is therefore our priority to make sure you enjoy your visit from the moment you arrive in Cape Town, with our modern fleet of vehicles and team of professional drivers, we can guarantee that your journey with us will be more than a pleasure!</p>

            </div>

            <section className="py-5 bg-white">
                <div className="col-10 col-lg-3 p-3 mx-auto rounded-3 bg-light text-center">
                    <h3>Make a booking</h3>
                    <hr/>
                    <h5>Contact: +27 79 218 1004</h5><br/>
                    <h5>Email: shafick@roseshuttles.com</h5>
                </div>
            </section>
            <footer className="text-center text-white p-3 py-lg-3 px-lg-5">&copy; Rose Shuttles 2020</footer>
        </main>
    )
}


export default Transfers;

